export const GET_ALL_LOUNGE_REQUEST = "GET_ALL_LOUNGE_REQUEST";
export const GET_ALL_LOUNGE_SUCCESS = "GET_ALL_LOUNGE_SUCCESS";
export const GET_ALL_LOUNGE_FAIL = "GET_ALL_LOUNGE_FAIL";

export const GET_FREE_LOUNGE_TIMING_REQUEST = "GET_FREE_LOUNGE_TIMING_REQUEST";
export const GET_FREE_LOUNGE_TIMING_SUCCESS = "GET_FREE_LOUNGE_TIMING_SUCCESS";
export const GET_FREE_LOUNGE_TIMING_FAIL = "GET_FREE_LOUNGE_TIMING_FAIL";

export const BOOK_MEETING_REQUEST = "BOOK_MEETING_REQUEST";
export const BOOK_MEETING_SUCCESS = "BOOK_MEETING_SUCCESS";
export const BOOK_MEETING_FAIL = "BOOK_MEETING_FAIL";
export const BOOK_MEETING_RESET = "BOOK_MEETING_RESET";

export const CANCEL_MEETING_REQUEST = "CANCEL_MEETING_REQUEST";
export const CANCEL_MEETING_SUCCESS = "CANCEL_MEETING_SUCCESS";
export const CANCEL_MEETING_FAIL = "CANCEL_MEETING_FAIL";
export const CANCEL_MEETING_RESET = "CANCEL_MEETING_RESET";

export const GET_DATE_WISE_MEETING_REQUEST = "GET_DATE_WISE_MEETING_REQUEST";
export const GET_DATE_WISE_MEETING_SUCCESS = "GET_DATE_WISE_MEETING_SUCCESS";
export const GET_DATE_WISE_MEETING_FAIL = "GET_DATE_WISE_MEETING_FAIL";

export const DELETE_MEETING_REQUEST = "DELETE_MEETING_REQUEST";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_RESET = "DELETE_MEETING_RESET";
export const DELETE_MEETING_FAIL = "DELETE_MEETING_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";