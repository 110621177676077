export const LOAD_ALL_LOUNGE_LIST_REQUEST = "LOAD_ALL_LOUNGE_LIST_REQUEST";
export const LOAD_ALL_LOUNGE_LIST_SUCCESS = "LOAD_ALL_LOUNGE_LIST_SUCCESS";
export const LOAD_ALL_LOUNGE_LIST_FAIL = "LOAD_ALL_LOUNGE_LIST_FAIL";

export const ADD_NEW_LOUNGE_REQUEST = "ADD_NEW_LOUNGE_REQUEST";
export const ADD_NEW_LOUNGE_SUCCESS = "ADD_NEW_LOUNGE_SUCCESS";
export const ADD_NEW_LOUNGE_FAIL = "ADD_NEW_LOUNGE_FAIL";
export const ADD_NEW_LOUNGE_RESET = "ADD_NEW_LOUNGE_RESET";

export const EDIT_NEW_LOUNGE_REQUEST = "EDIT_NEW_LOUNGE_REQUEST";
export const EDIT_NEW_LOUNGE_SUCCESS = "EDIT_NEW_LOUNGE_SUCCESS";
export const EDIT_NEW_LOUNGE_FAIL = "EDIT_NEW_LOUNGE_FAIL";
export const EDIT_NEW_LOUNGE_RESET = "EDIT_NEW_LOUNGE_RESET";

export const DELETE_NEW_LOUNGE_REQUEST = "DELETE_NEW_LOUNGE_REQUEST";
export const DELETE_NEW_LOUNGE_SUCCESS = "DELETE_NEW_LOUNGE_SUCCESS";
export const DELETE_NEW_LOUNGE_RESET = "DELETE_NEW_LOUNGE_RESET";
export const DELETE_NEW_LOUNGE_FAIL = "DELETE_NEW_LOUNGE_FAIL";

export const DELETE_LOUNGE_TIMING_REQUEST = "DELETE_LOUNGE_TIMING_REQUEST";
export const DELETE_LOUNGE_TIMING_SUCCESS = "DELETE_LOUNGE_TIMING_SUCCESS";
export const DELETE_LOUNGE_TIMING_RESET = "DELETE_LOUNGE_TIMING_RESET";
export const DELETE_LOUNGE_TIMING_FAIL = "DELETE_LOUNGE_TIMING_FAIL";

export const TOGGLE_NEW_LOUNGE_ACTIVATION_REQUEST = "TOGGLE_NEW_LOUNGE_ACTIVATION_REQUEST";
export const TOGGLE_NEW_LOUNGE_ACTIVATION_SUCCESS = "TOGGLE_NEW_LOUNGE_ACTIVATION_SUCCESS";
export const TOGGLE_NEW_LOUNGE_ACTIVATION_FAIL = "TOGGLE_NEW_LOUNGE_ACTIVATION_FAIL";
export const TOGGLE_NEW_LOUNGE_ACTIVATION_RESET = "TOGGLE_NEW_LOUNGE_ACTIVATION_RESET";

export const TOGGLE_LOUNGE_TIMING_REQUEST = "TOGGLE_LOUNGE_TIMING_REQUEST";
export const TOGGLE_LOUNGE_TIMING_SUCCESS = "TOGGLE_LOUNGE_TIMING_SUCCESS";
export const TOGGLE_LOUNGE_TIMING_FAIL = "TOGGLE_LOUNGE_TIMING_FAIL";
export const TOGGLE_LOUNGE_TIMING_RESET = "TOGGLE_LOUNGE_TIMING_RESET";

export const LOAD_TIMING_BY_LOUNGE_REQUEST = "LOAD_TIMING_BY_LOUNGE_REQUEST";
export const LOAD_TIMING_BY_LOUNGE_SUCCESS = "LOAD_TIMING_BY_LOUNGE_SUCCESS";
export const LOAD_TIMING_BY_LOUNGE_FAIL = "LOAD_TIMING_BY_LOUNGE_FAIL";

export const SET_LOUNGE_TIMING_REQUEST = "SET_LOUNGE_TIMING_REQUEST";
export const SET_LOUNGE_TIMING_SUCCESS = "SET_LOUNGE_TIMING_SUCCESS";
export const SET_LOUNGE_TIMING_FAIL = "SET_LOUNGE_TIMING_FAIL";
export const SET_LOUNGE_TIMING_RESET = "SET_LOUNGE_TIMING_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
